<template>
    <header class="aui-header-default aui-header-fixed t-bg">
        <div class="aui-header-center aui-header-center-clear">
            <div class="aui-header-center-logo">
                <div>
                    <img src="/images/pdx.png">
                </div>
            </div>
        </div>

    </header>
    <div class="clear"></div>
    <div class="hbox"></div>

    <div class="jsyhq pad0">


        <div class="mytb" v-for="(item, index) in promoList" >
            <!-- 秒杀分区不显示 -->
            <a v-bind:href="'/taxRefund/list?promoId=' + item.promoId" v-if="item.promoId != '6'">
                <div class="cq1">
                    <img :src="item.cateImg" />
                </div>
                <div class="cq2">
                    <p class="cq3">{{ item.promoName }}</p>
                </div>
                <div class="clear"></div>
            </a>
        </div>

    </div>
    <br><br>
    <div class="fbox"></div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { getPromoCategoryList } from '@/api/taxRefund.js'
const promoList = ref([])
onMounted(() => {
    getPromoCategoryList().then(res => {
        if (res && res.rows) {
            promoList.value = res.rows
        }
    })

})
</script>

<style scoped>
.cq1 {
    width: 100%;
}

.cq3 {
    text-align: center;
    color: #333;
    font-size: 1.2rem;
    line-height: 1.5rem;

}

.t-bg {
    background-image: url('../../static/images/bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>