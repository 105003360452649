<template>
    <div class="headerbox">
        <div class="header">
            <div class="headerL">
                <a onclick="javascript:history.back(-1)" class="goback"><img src="../../static/images/goback.png" /></a>
            </div>
            <div class="headerC">
                <p>绑定收货地址</p>
            </div>
        </div>
    </div>
    <div class="clear"></div>
    <div class="hbox"></div>
    <div class="kbox"></div>
    <div class="addressDiv">
        <div class="addiv1">
            <div class="addiv1_l">会员ID：</div>
            <div class="addiv1_r">{{ userInfo.id }}</div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">账号：</div>
            <div class="addiv1_r">{{ userInfo.account }}</div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">收货地址：</div>
            <div class="addiv1_r"><input id="address" type="text" v-model="userInfo.shippingAddress" /></div>
        </div>
        <div class="addiv1" style="text-align:center">

            <a class="aui-btn aui-btn-danger" @click="submitChange">修改资料</a>
        </div>
    </div>
</template>
    
<script setup>
import { ref, onMounted } from 'vue'
import { modifyUserInfo } from '@/api/authed/settings'
import { showToast } from 'vant';

const userInfo = ref({})
onMounted(() => {
    userInfo.value = JSON.parse(localStorage.getItem('userInfo'))

})

const submitChange = () => {
    if (userInfo.value.shippingAddress == '' || userInfo.value.shippingAddress == null || userInfo.value.shippingAddress == undefined) {
        showToast('收货地址不能为空')
        return
    }

    const param = { shippingAddress: userInfo.value.shippingAddress, id: userInfo.value.id }
    modifyUserInfo(param).then(res => {
        if (res.code == 200) {
            showToast('修改成功')
            localStorage.setItem('userInfo', JSON.stringify(userInfo.value))
        } else {
            showToast(res.msg)
        }
    })
}
</script>