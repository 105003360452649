<template>
    <div class="headerbox">
        <div class="header">
            <div class="headerL">
                <a onclick="javascript:history.back(-1)" class="goback"><img src="../../static/images/goback.png" /></a>
            </div>
            <div class="headerC">
                <p>个人资料</p>
            </div>
        </div>
    </div>
    <div class="clear"></div>
    <div class="hbox"></div>
    <div class="kbox"></div>
    <div class="addressDiv">
        <div class="addiv1">
            <div class="addiv1_l">会员ID：</div>
            <div class="addiv1_r">{{userInfo.id}}</div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">账号：</div>
            <div class="addiv1_r">{{ userInfo.account }}</div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">昵称：</div>
            <div class="addiv1_r red"><input id="nic" type="text" v-model="nickname" />
            </div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">姓名：</div>
            <div class="addiv1_r"><input id="xm" type="text" v-model="realName" /></div>
        </div>
        <div class="addiv1" style="text-align:center">

            <a class="aui-btn aui-btn-danger" @click="submitChange">修改资料</a>
        </div>

    </div>
    <div class="clear"></div>
    <div class="kbox"></div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { showToast } from 'vant';
import { modifyUserInfo } from '@/api/authed/settings'
const nickname = ref('')
const realName = ref('')
const userInfo = ref({})
const rex = /^[\u4e00-\u9fa5]{1,}$/
const submitChange = () => {
    if (nickname.value == '' || nickname.value == null || nickname.value == undefined) {
        showToast('昵称不能为空')
        return
    }
    if (!rex.test(nickname.value)) {
        showToast('昵称必须为中文')
        return
    }
    if (realName.value == '' || realName.value == null || realName.value == undefined) {
        showToast('姓名不能为空')
        return
    }
   
    if (!rex.test(realName.value)) {
        showToast('姓名必须为中文')
        return
    }

    if (nickname.value === userInfo.value.nickname && realName.value === userInfo.value.realName) {
        showToast('请修改后再提交')
        return
    }

    const param = { nickname: nickname.value, realName: realName.value, id: userInfo.value.id }
    modifyUserInfo(param).then(res => {
        if (res.code == 200) {
            showToast('修改成功')
            userInfo.value.nickname = nickname.value
            userInfo.value.realName = realName.value
            localStorage.setItem('userInfo', JSON.stringify(userInfo.value))
        } else {
            showToast(res.msg)
        }
    })
}

onMounted(() => {
    userInfo.value = JSON.parse(localStorage.getItem('userInfo'))
    nickname.value = userInfo.value.nickname
    realName.value = userInfo.value.realName
})
</script>