<template>
    <div class="headerbox">
        <div class="header">
            <div class="headerL">
                <a onclick="javascript:history.back(-1)" class="goback"><img src="../../static/images/goback.png" /></a>
            </div>
            <div class="headerC">
                <p>绑定银行卡</p>
            </div>
        </div>
    </div>
    <div class="clear"></div>
    <div class="hbox"></div>
    <div class="kbox"></div>
    <div class="addressDiv">
        <div class="addiv1">
            <div class="addiv1_l">会员ID：</div>
            <div class="addiv1_r">{{ userInfo.id }}</div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">账号：</div>
            <div class="addiv1_r">{{ userInfo.account }}</div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">姓名：</div>
            <div class="addiv1_r"><input id="xm" type="text" v-model="userInfo.realName" /></div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">银行：</div>
            <div class="addiv1_r"><input id="zfb" type="text" v-model="userInfo.bank" /></div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">卡号：</div>
            <div class="addiv1_r"><input id="kh" type="text" v-model="userInfo.bankCardNo" /></div>
        </div>
        <div class="addiv1" style="text-align:center">

            <a class="aui-btn aui-btn-danger" @click="submitChange">修改资料</a>
        </div>
    </div>
</template>
    
<script setup>
import { ref, onMounted } from 'vue'
import { modifyUserInfo } from '@/api/authed/settings'
import { showToast } from 'vant';

const userInfo = ref({})
const rex = /^[\u4e00-\u9fa5]{1,}$/

onMounted(() => {
    userInfo.value = JSON.parse(localStorage.getItem('userInfo'))

})

const submitChange = () => {
    if (userInfo.value.realName === '' || userInfo.value.realName === null || userInfo.value.realName === undefined) {
        showToast('姓名不能为空')
        return
    }
   
    if (!rex.test(userInfo.value.realName )) {
        showToast('姓名必须为中文')
        return
    }
    if (userInfo.value.bank == '' || userInfo.value.bank == null || userInfo.value.bank == undefined) {
        showToast('银行名称不能为空')
        return
    }

    if (userInfo.value.bankCardNo == '' || userInfo.value.bankCardNo == null || userInfo.value.bankCardNo == undefined) {
        showToast('卡号不能为空')
        return
    }

    const param = { realName: userInfo.value.realName, bank: userInfo.value.bank, bankCardNo: userInfo.value.bankCardNo, id: userInfo.value.id }
    modifyUserInfo(param).then(res => {
        if (res.code == 200) {
            showToast('修改成功')
            localStorage.setItem('userInfo', JSON.stringify(userInfo.value))
        } else {
            showToast(res.msg)
        }
    })
}
</script>