<template>
    <div class="headerbox">
        <div class="header">
            <div class="headerL">
                <a onclick="javascript:history.back(-1)" class="goback"><img src="../../static/images/goback.png" /></a>
            </div>
            <div class="headerC">
                <p>绑定支付宝</p>
            </div>
        </div>
    </div>
    <div class="clear"></div>
    <div class="hbox"></div>
    <div class="kbox"></div>
    <div class="addressDiv">
        <div class="addiv1">
            <div class="addiv1_l">会员ID：</div>
            <div class="addiv1_r">{{ userInfo.id }}</div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">账号：</div>
            <div class="addiv1_r">{{ userInfo.account }}</div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">姓名：</div>
            <div class="addiv1_r red">{{ userInfo.realName }}</div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">登录密码：</div>
            <div class="addiv1_r"><input id="p" type="password" v-model="oldPassword" /></div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">新密码：</div>
            <div class="addiv1_r"><input id="p1" type="password" v-model="newPassword" /></div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">重复密码：</div>
            <div class="addiv1_r"><input id="p2" type="password" v-model="repeatPassword" /></div>
        </div>
        <div class="addiv1" style="text-align:center">

            <a class="aui-btn aui-btn-danger" @click="submitChange">修改资料</a>
        </div>

    </div>
</template>
    
<script setup>
import { ref, onMounted } from 'vue'
import { modifyUserInfo } from '@/api/authed/settings'
import { showToast } from 'vant';

const userInfo = ref({})
const oldPassword = ref('')
const newPassword = ref('')
const repeatPassword = ref('')
const rex = /^.{6,32}$/
onMounted(() => {
    userInfo.value = JSON.parse(localStorage.getItem('userInfo'))

})

const submitChange = () => {
    if (oldPassword.value == '' || oldPassword.value == null || oldPassword.value == undefined) {
        showToast('登录密码不能为空')
        return
    }

    if (newPassword.value == '' || newPassword.value == null || newPassword.value == undefined) {
        showToast('新密码不能为空')
        return
    }

    if (repeatPassword.value == '' || repeatPassword.value == null || repeatPassword.value == undefined) {
        showToast('重复密码不能为空')
        return
    }

    if (!rex.test(newPassword.value)) {
        showToast('新密码长度必须为6-32位')
        return
    }

    if (newPassword.value != repeatPassword.value) {
        showToast('两次密码不一致')
        return
    }

    const param = { oldPassword: oldPassword.value, password: newPassword.value, id: userInfo.value.id }
    modifyUserInfo(param).then(res => {
        if (res.code == 200) {
            showToast('修改成功')
            localStorage.setItem('userInfo', JSON.stringify(userInfo.value))
        } else {
            showToast(res.msg)
        }
    }).catch(err => {
        showToast("修改失败,原密码错误")
    })
}
</script>