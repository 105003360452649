import request from '@/utils/request'

export function login(username, password, code, uuid) {
    const data = {
        username,
        password,
        code,
        uuid
    }
    return request({
        url: '/user/login',
        method: 'post',
        data: data
    })
}



export function register(username, password,nickname, code, uuid) {
    const data = {
        username,
        password,
        nickname,
        code,
        uuid
    }
    return request({
        url: '/user/register',
        method: 'post',
        data: data
    })
}

// 获取用户详细信息
export function getInfo() {
    return request({ 
        url: '/getInfo',
        method: 'post'
    })
}

// 退出方法
export function logout() {
    return request({
        url: '/logout',
        method: 'post'
    })
} 

export function getCodeImg(){
    return request({
        url: '/user/code',
        method: 'get'
    })
}