<template>
    <div class="m0myheader">
        <div class="conbox">
            <div class="conboxL">
                <img src="/images/tx.png" class="tt" />
                <div class="btR">
                    <p class="p1">{{ userOverview?userOverview.account:'' }}</p>
                    <div class="v1">
                        <img :src="levelImgSrc" />
                        <p>{{ userOverview?userOverview.nickname:'' }}</p>
                    </div>
                </div>
            </div>
            <div class="conboxR">
                <a v-bind:href="'/settings'">设置</a>
            </div>
            <div class="clear"></div>
        </div>
        <div class="conbox2">
            <ul>
                <li>
                    <a href="javascript:void()">
                        <p class="p1">{{userOverview? (Number(userOverview.balance)/100).toFixed(2):'' }}</p>
                        <p class="p2">余额</p>
                    </a>
                </li>
                <li>
                    <a href="javascript:void()">

                        <p class="p1">{{userOverview? Number(userOverview.totalTaxReturned).toFixed(2):'' }}</p>
                        <p class="p2">返还</p>
                    </a>
                </li>
                <li>
                    <a href="javascript:void()">
                        <p class="p1">{{userOverview? userOverview.creditScore:'' }}</p>
                        <p class="p2">信誉分</p>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="clear"></div>
    <div class="mypart1">
        <ul>
            <li>
                <a v-bind:href="'/tabList/0'">
                    <p class="p1">{{ userOverview?Number(userOverview.waitGroupPurchase).toFixed(2):'' }}</p>
                    <p class="p2">等待中</p>
                </a>
            </li>
            <li>
                <a v-bind:href="'/tabList/1'">
                    <p class="p1">{{ userOverview?Number(userOverview.succeedGroupPurchase).toFixed(2):'' }}</p>
                    <p class="p2">已购买</p>
                </a>
            </li>
            <li>
                <a v-bind:href="'/tabList/2'">
                    <p class="p1">{{ userOverview?Number(userOverview.waitTaxReturn).toFixed(2):'' }}</p>
                    <p class="p2">申请中</p>
                </a>
            </li>
            <li>
                <a v-bind:href="'/tabList/3'">
                    <p class="p1">{{ userOverview?Number(userOverview.alreadyTaxReturned).toFixed(2):'' }}</p>
                    <p class="p2">已返还</p>
                </a>
            </li>
            <li>
                <a v-bind:href="'/fundDetail'">
                    <img src="../../static/images/my01.png" />
                    <p class="p2">全部记录</p>
                </a>
            </li>
        </ul>
    </div>
    <div class="kbox"></div>
    <div class="kbox"></div>
    <div class="clear"></div>
    <div class="mypart2">
        <div class="con">
            <div class="pa2_tit">
                <p>我的订单</p>
                <a v-bind:href="'/tabList/all'">查看更多订单 ></a>
            </div>
            <ul>
                <li>
                    <a v-bind:href="'/tabList/all'">
                        <div class="ddimg">
                            <img src="../../static/images/my02.png" />
                        </div>
                        <p>全部订单</p>
                    </a>
                </li>
                <li>
                    <a v-bind:href="'/tabList/0'">
                        <div class="ddimg">
                            <img src="../../static/images/my03.png" />
                        </div>
                        <p>等待中</p>
                    </a>
                </li>
                <li>
                    <a v-bind:href="'/tabList/1'">
                        <div class="ddimg">
                            <img src="../../static/images/my04.png" />
                        </div>
                        <p>已购买</p>
                    </a>
                </li>
                <li>
                    <a v-bind:href="'/tabList/2'">
                        <div class="ddimg">
                            <img src="../../static/images/my06.png" />
                        </div>
                        <p>申请中</p>
                    </a>
                </li>
                <li>
                    <a v-bind:href="'/tabList/3'">
                        <div class="ddimg">
                            <img src="../../static/images/my14.png" />
                        </div>
                        <p>已返还</p>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="clear"></div>
    <div class="kbox"></div>
    <div class="kbox"></div>
    <div class="clear"></div>
    <div class="mypart3">
        <ul>
            <li>
                <a v-bind:href="'/mine/recharge'">
                    <img src="../../static/images/my11.png" />
                    <p>在线充值</p>
                </a>
            </li>
            <li>
                <a v-bind:href="'/rechargeRecord'">
                    <img src="../../static/images/my12.png" />
                    <p>充值记录</p>
                </a>
            </li>
            <li>
                <a v-bind:href="'/settings'">
                    <img src="../../static/images/my13.png" />
                    <p>个人设置</p>
                </a>
            </li>
            <li>
                <a v-bind:href="'/mine/message'">
                    <img src="../../static/images/my05.png" />
                    <p>我的消息</p>

                </a>
            </li>
            <li>
                <a v-bind:href="'/applyWithdraw'">
                    <img src="../../static/images/my7.png" />
                    <p>申请提现</p>
                </a>
            </li>
            <li>
                <a v-bind:href="'/withdrawRecord'">
                    <img src="../../static/images/my9.png" />
                    <p>提现记录</p>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)">
                    <img src="../../static/images/my8.png" />
                    <p>APP下载</p>
                </a>
            </li>
            <li>
                <a v-bind:href="'/customerService'">
                    <img src="../../static/images/my10.png" />
                    <p>客服</p>
                </a>
            </li>
        </ul>
    </div>

    <div class="kbox"></div>
    <div class="kbox"></div>
    <div class="clear"></div>
    <div class="mypart4">
        <img src="../../static/images/my15.png" />
    </div>
    <div class="likebox my">
        <ul>

            <li v-for="(item, index) in recommendList">
                <a v-bind:href="`/taxRefund/detail/${item.id}`">
                    <img :src="item.productImg" class="proimg" />
                    <p class="tit">{{item.productName}}</p>
                    <p class="price">￥{{item.groupPrice}}<span>￥{{item.marketPrice}}</span></p>
                    <p class="price"
                        style="background-color: #F79764; text-align: center; vertical-align: middle; line-height: 11px; color: red">
                        积分:￥{{item.taxReturned}}</p>
                </a>
            </li>


        </ul>
    <br><br><br>
    </div>
    

</template>

<script setup>
import { ref, onMounted } from 'vue'
import { getUserOverview, getRecommendList } from '@/api/authed/mine.js'
const userOverview = ref(null)
const recommendList = ref([])
const levelImgSrc = ref('')
onMounted(() => {
    getUserOverview().then(res => {
        if (res && res.data) {
            userOverview.value = res.data
            levelImgSrc.value = '/images/vip' + userOverview.value.vipLevel + '.jpg'
        }
    })

    getRecommendList().then(res => {
        if (res && res.rows) {
            recommendList.value = res.rows
        }
    })
})

</script>



<style scoped></style>