import request from '@/utils/request'



// 获取促销区列表
export function getPromoCategoryList() {

    return request({
        url: '/promoCategory/list',
        method: 'get',
    })
}

export function getPromoCategory(id) {

    return request({
        url: '/promoCategory/promo/' + id,
        method: 'get',
    })
}

export function getProductList(promoId) {
    return request({
        url: '/product/list?promoId=' + promoId +'&pageNum=1&pageSize=100',
        method: 'get',
    })
}


export function getProductDetail(detailId) {

    return request({
        url: '/product/' + detailId,
        method: 'get',
    })
}


export function confirmPay(detailId, orderId) {
    const data = { detailId: detailId, orderId: orderId }
    return request({
        url: '/mine/confirmGroupPurchasePay',
        method: 'post',
        data: data
    })
}

export function getGroupPurchaseList(detailId) {

    return request({
        url: '/product/groupPurchaseList/' + detailId,
        method: 'get',
    })
}

