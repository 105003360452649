import request from '@/utils/request'

// 获取产品列表
export function getList(groupId) {
 
    return request({
        url: '/vipshopProduct/list?groupId='+ groupId+'&pageNum=1&pageSize=100',
        method: 'get',
    })
}


export function getGroupList() {
 
    return request({
        url: '/vipshopCategory/list' ,
        method: 'get',
    })
}

export function getFlashSaleList() {
 
    return request({
        url: '/vipshopProduct/flashSaleList' ,
        method: 'get',
    })
}


export function getGuessLikeList() {
 
    return request({
        url: '/vipshopProduct/guessLikeList' ,
        method: 'get',
    })
}

export function getProductDetail(id) {
 
    return request({
        url: '/vipshopProduct/'+id ,
        method: 'get',
    })
}
