import user from './modules/user'
import getters from './getters'
import {defineStore} from 'pinia'
const store = defineStore('carStoreId',{
    // 这种写法 有道友反馈看起来 还是需要反应下 😓😓
    // state:()=>({})
    state:()=>{
        return{
            carList:[]
        }
    },
    modules: {
      user
    },
    getters
})

export default store