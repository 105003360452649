<template>
    <header class="aui-header-default aui-header-fixed t-bg">
        <div class="aui-header-center aui-header-center-clear">
            <div class="aui-header-center-logo">
                <div>
                    <img src="../static/images/icon-dis.png" alt="">
                </div>
            </div>
        </div>

    </header>
    <div class="clear"></div>
    <section class="n-tabs" style="text-align: center;">
        <ul class="n-tabContainer" id="auto-id-1509603311057">
            <li v-for="(item,index) in groupList" class="n-tabItem" >
                <a v-bind:href="'/shopList?type=' + item.groupId"  :id="'type'+item.groupId" :class="item.groupId == currentGroupId?'current':''">{{item.categoryName.substr(0,2)}}</a>
            </li>

        </ul>
    </section>
    <div class="clear"></div>
    <div class="hbox1"></div>
    <div class="tolist">
        <img src="../static/images/tolist.png" @click="changeList()">
    </div>
    <div class="totop">
        <a href="javascript:scrollTo(0,0)">
            <img src="../static/images/totop.png"></a>
    </div>
    <div class="kbox"></div>
    <div class="likebox" v-show="isShowLikeBox">
        <ul>


            <li v-for="(item, index) in productList">
                <router-link :to="`/shopList/${item.id}`">
                    <img :src="item.productImg" :alt="item.name" class="proimg" />
                    <p class="tit">{{ item.productName }}</p>
                    <p class="price">￥{{ item.vipPrice }}<span>￥{{ item.marketPrice }}</span></p>
                </router-link>
            </li>
        </ul>
    </div>
    <div class="shoplist" style="display: none" v-show="!isShowLikeBox">
        <ul>


            <li v-for="(item,index) in productList" >
                <a :href="`/shopList/${item.id}`">
                    <div class="listL">
                        <img :src="item.productImg"
                            :alt="item.productName" class="proimg" />
                    </div>
                    <div class="listR">
                        <div class="v1" >{{item.productName}}</div>
                        <div class="v2" ><span>包邮</span></div>
                        <div class="v3">
                            <p class="p1">￥{{ item.vipPrice }}<span>￥{{ item.marketPrice }}</span></p>
                            <p class="p2">{{item.salesVolume}}人付款</p>
                        </div>
                    </div>
                </a>
            </li>

        </ul>
    </div>

    <div class="fbox"></div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { getList, getGroupList } from '../api/vipshopProduct.js'
const route = useRoute()
const currentGroupId = route.query.type
const isShowLikeBox = ref(true)

const changeList = () => {
    isShowLikeBox.value = !isShowLikeBox.value
}
const productList = ref([])
const groupList = ref([])

onMounted(() => {
   


    getGroupList().then(res => {
        if (res && res.rows) {
            groupList.value = res.rows
            
        }
    })

    getList(route.query.type).then(res => {
        if (res && res.rows) {
            productList.value = res.rows
            console.log(productList.value[0])
        }
    })

})

</script>

<style>

</style>
