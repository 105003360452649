import request from '@/utils/request'


export function modifyUserInfo(data) {
    return request({
        url: '/user/modifyUserInfo',
        method: 'post',
        data:data
    })
}

export function getRecommendList() {
    return request({
        url: '/mine/recommendList',
        method: 'get'
    })
}


