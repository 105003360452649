<template>
    <div class="headerbox">
        <div class="header">
            <div class="headerL">
                <a onclick="javascript:history.back(-1)" class="goback">
                    <img src="../../static/images/goback.png" /></a>
            </div>
            <div class="headerC">
                <p>提现记录</p>
            </div>
            <div class="headerR"></div>
        </div>
    </div>
    <div class="clear"></div>
    <div class="hbox"></div>

    <div class="clear"></div>
    <div class="kbox"></div>
    <div class="jfbox">


        <div class="jfbox1" v-for="(item, index) in myWithdrawList">
            <div class="jfbox1_R">
                <div class="jfbox1_R1">
                    <div class="v1"><van-button size="mini" disabled>提现</van-button></div>
                    <div class="v2"><van-button size="mini" disabled>{{ item.time }}</van-button></div>
                </div>
                <div class="jfbox1_R2">
                    <van-button size="mini" :type="getStatusButton(item.status)" disabled>{{ getStatusStr(item.status) }}</van-button>
                    <div class="v4"><van-button size="mini" type="primary" disabled>{{ item.amount.toFixed(2) }}</van-button></div>
                </div>
            </div>
            <div class="clear"></div>
        </div>
    </div>
    <br><br><br>
</template>
    
<script setup>
import { ref, onMounted } from 'vue'
import { getMyWithdrawList,  } from '@/api/authed/mine'
const myWithdrawList = ref([])
onMounted(() => {
    getMyWithdrawList().then(res => {
        myWithdrawList.value = res.data
    })
})

const getStatusStr = (status) => {
    switch (status) {
        case 20:
            return '申请提现'
        case 21:
            return '同意'
        case 22:
            return '拒绝提现'
        default:
            return '未知'
    }
}

const getStatusButton = (status) => {
    switch (status) {
        case 20:
            return 'warning'
        case 21:
            return 'success'
        case 22:
            return 'danger'
        default:
            return 'default'
    }
}

</script>