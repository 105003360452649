<template>
    <div class="headerbox">
        <div class="header">
            <div class="headerL">
                <a onclick="javascript:history.back(-1)" class="goback">
                    <img src="../../static/images/goback.png" /></a>
            </div>
            <div class="headerC">
                <p>消费记录</p>
            </div>
            <div class="headerR"></div>
        </div>
    </div>
    <div class="clear"></div>
    <div class="hbox"></div>

    <div class="clear"></div>
    <div class="kbox"></div>
    <div class="jfbox">

        <div class="jfbox1" v-for="(item, index) in fundDetailList">
            <div class="jfbox1_R">
                <div class="jfbox1_R1">
                    <div class="v1">{{ item.project }}</div>
                    <div class="v2">{{ item.time }}</div>
                </div>
                <div class="jfbox1_R2">
                    <div class="v3">余额:{{ (item.balance / 100).toFixed(2) }}</div>
                    <div class="v4">{{ item.amount > 0 ? '+' : '' }}{{ (item.amount / 100).toFixed(2) }}</div>
                </div>
            </div>
            <div class="clear"></div>
        </div>

    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { getFundDeailList } from '@/api/authed/mine.js'
const fundDetailList = ref([])
onMounted(() => {
    getFundDeailList().then(res => {
        if (res && res.data) {
            fundDetailList.value = res.data
        }
    })

})
</script>

<style scoped></style>