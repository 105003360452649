<template>
    <div class="hdbox">
        <div class="hdbox0">
            <div class="hdbox_1">
                <a onclick="javascript:history.back(-1)" class="goback">
                    <img src="../../static/images/goback.png"></a>
            </div>
            <div class="hdbox_2">
                <ul>
                    <li class="on"><a href="#m1">商品</a></li>
                    <li><a href="#m2">详情</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="hbox"></div>
    <a name="m1">
        <div class="xqbox1">
            <div class="banner">
                <div id="fade_focus">
                    <div class="loading"></div>
                    <ul>

                        <li>
                            <img src="" />
                        </li>

                    </ul>
                </div>
            </div>
            <div class="aui-product-content">
                <div class="aui-real-price clearfix">
                    <span>
                        <i>￥</i>{{ detail.groupPrice }}
                    </span>
                    <del><span class="aui-font-num">￥{{ detail.marketPrice }}</span></del>
                    <div class="aui-settle-choice">
                        <span>积分:{{ detail.taxReturned }}</span>
                    </div>
                </div>
                <div class="aui-product-title">
                    <h2>
                        {{ detail.productName }}
                    </h2>
                </div>

                <div class="aui-product-coupon">
                    <a class="aui-address-cell aui-fl-arrow-clear">
                        <div class="aui-address-cell-bd">运费</div>
                        <div class="aui-address-cell-ft">免运费</div>
                    </a>
                    <a class="aui-address-cell aui-fl-arrow-clear">
                        <div class="aui-address-cell-bd">说明</div>
                        <div class="aui-address-cell-ft">假一赔十 7天无忧退货</div>
                    </a>
                    <a class="aui-address-cell aui-fl-arrow-clear">
                        <div class="aui-address-cell-bd">销量</div>
                        <div class="aui-address-cell-ft">{{ detail.salesVol }}</div>
                    </a>
                </div>
            </div>



            <div v-if="groupPurchaseList.length>0" class="price" style="margin-left: 15px;">一共有{{ groupPurchaseList.length }}人在开团,可直接参与</div>

            <a v-for="(item, index) in groupPurchaseList" @click="joinGroup(item.orderNo, item.account)">
                <div class="aui-address-cell" style="margin: 0; padding: 0">
                    <div class="aui-address-cell" style="width: 35px;">
                        <img src="/images/pdd.png" style="width: 35px; height: 35px" />
                    </div>
                    <div class="aui-address-cell-bd">
                        {{item.nickname}}
                    </div>
                    <div class="aui-address-cell-bd">还差1人开团</div>
                    <div class="aui-address-cell-ft">
                        <span class="aui-btn aui-btn-danger">开团</span>
                    </div>
                </div>
            </a>



        </div>
    </a>

    <a name="m2">
        <div class="kbox"></div>
    </a>

    <div class="xqtab">
        <div class="Menubox">
            <ul>
                <li class="hover" onclick="setTab('two',1,3)" id="two1">图文详情</li>
            </ul>
        </div>
        <div class="Contentbox">
            <div id="con_two_1">
                <div class="xqsub">
                    <ul>
                        <li>
                            <img v-for='(item, index) in descImages' :src="item" />
                        </li>

                    </ul>
                </div>
            </div>

        </div>
    </div>
    <br><br>
    <div class="clear"></div>
    <div class="xqbotbox">
        <div class="xqbotbox0">
            <div class="xqbotboxR">
                <a class="aui-btn aui-btn-danger a2" @click="checkLogin">立即开团
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { getProductDetail, getGroupPurchaseList } from '@/api/taxRefund.js'
import { useRoute, useRouter } from 'vue-router'
import {showToast} from 'vant'
const route = useRoute()
const router = useRouter()
const detailId = route.params.id
const orderId = ref('')
const detail = ref({})
const descImages = ref([])
const groupPurchaseList = ref([])
const userInfo = ref({})
onMounted(() => {
    getProductDetail(detailId).then(res => {
        if (res && res.data) {
            detail.value = res.data
            if (res.data.productDesc) {
                descImages.value = res.data.productDesc.split(',')
            }
            document.title = res.data.productName
            if(detail.value.productId!=null){
                getGroupPurchaseList(detail.value.productId).then(res => {
                if (res && res.data) {
                    groupPurchaseList.value = res.data
                }
            })
            }
        }
    })
    userInfo.value = JSON.parse(localStorage.getItem('userInfo'))
})
const joinGroup = (orderNo, account) => {
    if(userInfo.value == null){
        router.push('/login')
        return
    }
    if (account == userInfo.value.account) {
        showToast('不能参与自己的团购')
        return
    }
    orderId.value = orderNo
    checkLogin()
}

const checkLogin = () => {
    if (localStorage.getItem('token')) {
        if(orderId.value=='' && groupPurchaseList.value.length>0){
           for(let i=0;i<groupPurchaseList.value.length;i++){
               if(groupPurchaseList.value[i].account==userInfo.value.account){
                   showToast('您已经参与过该团购')
                   return
               }
           }
        }
        router.push('/confirmGroupPurchase?id=' + detailId + '&orderId=' + orderId.value)
    } else {
        router.push('/login')
    }
}


</script>

<style></style>